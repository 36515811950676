export const clientList = [
  {
    id:"dadson",
    label:"Dadson Laundry",
    image:"/clients/logo-dadson.webp",
    style:{marginBottom:"0.3rem"},
    text:"",
    url:"https://dadsonlaundry.com/",
    linkText:""
  },
  {
    id:"nccwb",
    label:"Center for Well-Being",
    image:"/clients/logo-cwb.webp",
    style:{marginBottom:"0.4rem"},
    text:"",
    url:"https://www.norcalwellbeing.org/",
    linkText:""
  },
  {
    id:"cmoar",
    label:"Clínica Monseñor Oscar A. Romero",
    image:"/clients/logo-cmoar.webp",
    style:{},
    text:"CMOAR as we like to call them was started in 1983 by a group of Salvadorean civil war refugees in the LA community. They are the largest Salvadorean healthcare group in the nation. They provide full medical services to the underserved residents throughout the Greater Los Angeles area and they love to help people.",
    url:"https://www.clinicaromero.com/",
    linkText:"clinicaromero.com"
  },
  {
    id:"wag",
    label:"Wilshire Advisory Group",
    image:"/clients/logo-wag.webp",
    style:{marginBottom:"0.4rem"},
    text:"",
    url:"https://wilshireag.com/",
    linkText:""
  },
  {
    id:"lbbk",
    label:"Lagasse Branch Bell + Kinkead",
    image:"/clients/logo-lbbk.webp",
    style:{marginBottom:"0.3rem"},
    text:"LBBK is another company that we have had the opportunity to watch grow from its inception of the four partners and a few employees to over 30 attorneys! They are 100% female and minority owned firm providing expertise in many areas such as Employment and Education Law, Business Transactions, Real Estate & Finance, and Construction Litigation. If you ever need any legal support, give them a call.",
    url:"https://www.lbbklaw.com/",
    linkText:"lbbklaw.com"
  },
  {
    id:"sac",
    label:"SAC Health Care",
    image:"/clients/logo-sac.webp",
    style:{marginBottom:"0.3rem"},
    text:"Social Action Corps (SAC) began in the late 1960s as an outreach effort from students and staff at Loma Linda University and Medical Center. Over the course of 30 years, these volunteers offered temporary medical clinics in donated spaces within the community. In 1995, SAC incorporated into a 501(c)(3) community health care corporation and became SAC Health, a three-clinic system in the City of San Bernardino. Today, SAC provides patients with world-class care in over 35 different specialties at 7 locations, and is also the largest specialty-based and teaching health center (THC) FQHC in the nation.",
    url:"https://sachealth.org/",
    linkText:"Visit SAC Health"
  },
  {
    id:"urm",
    label:"Unified Residential Management",
    image:"/clients/logo-urm.webp",
    style:{marginBottom:"-0.1rem"},
    text:"",
    url:"https://www.unified-residential.com/",
    linkText:""
  },
  {
    id:"mle",
    label:"Med-Legal Evaluators",
    image:"/clients/logo-med-legal.webp",
    style:{},
    text:"",
    url:"http://www.medlegalevals.com/",
    linkText:""
  },
  {
    id:"pc",
    label:"Parking Concepts",
    image:"/clients/logo-pci.webp",
    style:{marginBottom:"0.1rem"},
    text:"",
    url:"https://parkingconcepts.com/",
    linkText:""
  },
  {
    id:"eca",
    label:"Every Child Achieves",
    image:"/clients/logo-eca.webp",
    style:{},
    text:"Our friends, Eli and Freddie Berger started ECA back in 2003. They specialize in all types of therapy for children – occupational, speech, physical, child development, sensory integration and more. Their team of in-home care specialists provide these amazing services to children to assist with their development.",
    url:"https://ecatherapy.com/",
    linkText:"See for yourself"  
  },
  {
    id:"pdlr",
    label:"Plaza De La Raza Child Development",
    image:"/clients/logo-pdlr.webp",
    style:{marginBottom:"0.1rem"},
    text:"Plaza de la Raza is a non-profit organization providing high-quality early education and comprehensive developmental services to families with children ages 0 – 5 years old and pregnant women. For over 35 years, the agency has worked together with families to aid and support in the prevention of adverse childhood experiences. They are dedicated to breaking the cycles of inter-generational disparities, ensuring equitable access to imperative tools that promote school readiness, all while empowering parents to advocate for themselves and their children.",
    url:"https://plazadelarazacds.org/",
    linkText:"Visit PDLR"
  },
  {
    id:"cor",
    label:"Cordova Consulting",
    image:"/clients/logo-cordova-consulting.webp",
    style:{marginBottom:"-0.35rem"},
    text:"",
    url:"https://ccrespite.com/",
    linkText:""
  },
  {
    id:"kap",
    label:"Kaplan Financial",
    image:"/clients/logo-kaplan-financial.webp",
    style:{marginBottom:"-0.5rem"},
    text:"",
    url:"https://www.kaplanfinancial.net/",
    linkText:""
  },
  {
    id:"lcs",
    label:"Larchmont Charter School",
    image:"/clients/logo-larchmont-charter.webp",
    style:{},
    text:"",
    url:"https://www.larchmontcharter.org/",
    linkText:""
  },
  {
    id:"nel",
    label:"Nelson Recruiting",
    image:"/clients/logo-nelson-recruiting.webp",
    style:{marginBottom:"0.2rem"},
    text:"",
    url:"https://nelsonrecruiting.com/",
    linkText:""
  },
  {
    id:"pln",
    label:"Para Los Ninos",
    image:"/clients/logo-para-los-ninos.webp",
    style:{marginBottom:"-0.1rem"},
    text:"",
    url:"https://paralosninos.org/",
    linkText:""
  },
  {
    id:"ypics",
    label:"YPI Charter Schools",
    image:"/clients/logo-ypics.webp",
    style:{},
    text:"",
    url:"https://www.ypics.org/",
    linkText:""
  },
  /* {
    id:"ppnc",
    label:"Planned Parenthood of Northern California",
    image:"/clients/logo-ppnorcal.webp",
    style:{marginBottom:"-0.4rem"},
    text:"",
    url:"https://www.plannedparenthood.org/planned-parenthood-northern-california",
    linkText:""
  }, */
]