import Service from "../../components/Service"

const data = {
  name:"Windows Updates & 3rd Party Patching",
  title:"Windows & 3rd Party Patching Services You Can Trust",
  description:"Stay secure with reliable Windows and 3rd party patching services. Ensure your systems are updated to prevent vulnerabilities and enhance performance.",
  image:"/windows-patching.svg",
  text:["Are you installing Windows updates and patches manually? Once our engineers test and approve Windows patches and updates, our Remote Monitoring and Management (RMM) system proactively installs the updates behind the scenes. This ensures that all computers and servers are constantly being updated and stay as secure as possible. Our team will also update any Line of Business (LOB) applications whenever new versions or updates are released so that you are always up-to-date and in compliance!"],
  url:"/windows-updates-and-third-party-patching",
  alt:"Windows Updates and 3rd Party Patching"
}

const WindowsPatching = () => {
  return (
    <Service data={data} />
  )
}

export default WindowsPatching