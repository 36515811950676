import { Link } from 'react-router-dom'
import ScrollToTop from '../../components/buttons/ScrollToTop'

const PrivacyPolicy = (props) => {
  return (
    <div className='page'>
      <div className='page-container'>
        <div className='page-title'>
          <h1>Privacy Policy</h1>
        </div>
        <div className='content-container'>
          <div className='legal-content-section'>
            <p>At <b>7 Layer IT Solutions</b>, we respect and value your privacy. This Privacy Policy explains how we collect, use, and protect your personal data when you visit our website. By using this website, you agree to the collection and use of your information in accordance with this policy.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Information We Collect</h2>
            <p>
              We may collect the following types of information when you visit our website:
            </p>
            <p>
              <b>Personal Information:</b> We do not require users to create accounts or submit personal information to use the website. However, if you choose to contact us, we may collect your name, email address, and any other details you provide in your message.
            </p>
            <p>
              <b>Non-Personal Information:</b> We may collect non-personally identifiable information automatically through technologies like cookies and analytics tools (e.g., Google Analytics). This includes information about your device, browser type, IP address, pages visited, and the time you spend on our site.
            </p>
          </div>
          <div className='legal-content-section'>
            <h2>How We Use Your Information</h2>
            <p>
              <b>To Improve Our Website:</b> We use the information gathered to enhance and personalize your experience, improve website functionality, and analyze traffic patterns.
            </p>
            <p>
              <b>Communication:</b> If you choose to contact us or sign up for a newsletter (if applicable in the future), we may use your contact information to respond to your inquiries.
            </p>
            <p>
              <b>Analytics:</b> We use tools like Google Analytics to analyze traffic and user behavior on the website. This helps us better understand our audience and improve our content.
            </p>
          </div>
          <div className='legal-content-section'>
            <h2>Cookies and Tracking Technologies</h2>
            <p>
              Our website uses cookies and similar tracking technologies to enhance your experience. Cookies are small text files stored on your device when you visit the website. They help us analyze website traffic, track your preferences, and optimize the user experience.
            </p>
            <p>
              By using this website, you consent to our use of cookies. If you do not wish to accept cookies, you may disable them through your browser settings, though this may impact some website features.
            </p>
            <p>To find out more about how we use cookies, please see our Cookies Policy.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Google Analytics</h2>
            <p>We use Google Analytics, a service provided by Google, Inc., to gather insights into how users interact with our website. Google Analytics collects information such as IP addresses, pages visited, and the time spent on the site.</p>
            <p>For more information about Google Analytics and how to opt-out, please visit Google’s Privacy Policy.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Data Security</h2>
            <p>We take reasonable precautions to protect your personal data from unauthorized access, alteration, or disclosure. However, please be aware that no method of electronic storage or transmission is 100% secure, and we cannot guarantee the absolute security of your information.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Third-Party Links</h2>
            <p>Our website may contain links to external websites that are not operated by us. We are not responsible for the privacy practices or content of these third-party sites. Please review the privacy policies of any linked websites before providing them with your personal information.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Your Data Protection Rights</h2>
            <p>Depending on your location and applicable laws (e.g., GDPR), you may have certain rights regarding your personal data. These rights may include:</p>
            <p><b>Access:</b> You can request access to the personal data we hold about you.</p>
            <p><b>Correction:</b> You can request corrections to any inaccurate or incomplete data.</p>
            <p><b>Deletion:</b> You can request the deletion of your personal data, subject to certain conditions.</p>
            <p><b>Opt-out of Marketing:</b> If you receive marketing communications from us, you can opt-out at any time by following the unsubscribe instructions in our emails or contacting us directly.</p>
            <p>To exercise any of these rights, please <Link to='/contact'>contact us</Link>.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Please review this Privacy Policy periodically to stay informed about how we are protecting your information. This policy was last updated February 1, 2025.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal data, please <Link to="/contact"><b>contact us</b></Link>.</p>
          </div>
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

export default PrivacyPolicy
