import { Link } from 'react-router-dom'
import ScrollToTop from '../../components/buttons/ScrollToTop'

const TermsOfUse = (props) => {
  return (
    <div className='page'>
      <div className='page-container'>
        <div className='page-title'>
          <h1>Terms of Use</h1>
        </div>
        <div className='content-container'>
          <div className='legal-content-section'>
            <p>By accessing or using our website, you agree to comply with and be bound by the following terms and conditions (&quot;Terms&quot;). Please read them carefully. If you do not agree to these Terms, you should not use our website.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Use of the Website</h2>
            <p>By using this website, you agree to use it solely for lawful purposes and in a way that does not infringe the rights of, or restrict the use and enjoyment of, this website by any third party.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Content</h2>
            <p>The content provided on this website is for informational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties regarding the accuracy, completeness, or reliability of the content. The content is provided &quot;as is&quot; and may be subject to change without notice.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Limitation of Liability</h2>
            <p>To the fullest extent permitted by law, <b>7 Layer IT Solutions</b> is not liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use this website or any content provided on the website. We are not responsible for any damages to your computer system or data resulting from your access to or use of this website.</p>
          </div>
          <div className='legal-content-section'>
            <h2>External Links</h2>
            <p>This website may contain links to external websites or resources, which are provided for your convenience only. <b>7 Layer IT Solutions</b> has no control over the contents, privacy practices, or operation of these external websites or resources. If you decide to access any of the third party websites or resources linked this website, you do so entirely at your own risk and subject to the terms and conditions of such websites or resources.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Privacy</h2>
            <p>For information about how we collect, use, and protect your personal data, please refer to our <Link to='/privacy'><b>Privacy Policy</b></Link>.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Changes to Terms</h2>
            <p>We may update these Terms from time to time in order to maintain compliance with the law, and to reflect any changes to the way we operate this website. Please review these Terms periodically to stay informed of any updates.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Governing Law</h2>
            <p>These Terms are governed by the laws of the State of California, United States, and any disputes arising out of or related to these Terms will be subject to the exclusive jurisdiction of the courts located in Los Angeles, California, United States.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about these Terms, please <Link to="/contact"><b>contact us</b></Link>.</p>
          </div>
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

export default TermsOfUse
