import Service from "../../components/Service"

const data = {
  name:"Business Technology Audits",
  title:"Comprehensive Business Technology Audit Services",
  description:"Optimize your operations with a thorough business technology audit. Identify gaps, improve efficiency, and align IT systems with your goals.",
  image:"/business-technology-audit.svg",
  text:["Our job is to ensure that our clients use technology to gain a competitive advantage in their industry. We love meeting with clients to learn how we can help them work smarter and increase efficiency. We do not deliver cookie-cutter solutions. We customize each client’s solution based on their budget, needs and prospective growth. We meet with our clients often to ensure we are informed about their IT and operational goals so that we can strategize the best way to facilitate those changes."],
  url:"/business-technology-audits",
  alt:"Business Technology Audits"
}

const BusinessTechnologyAudit = () => {
  return (
    <Service data={data} />
  )
}

export default BusinessTechnologyAudit