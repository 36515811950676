import Service from "../../components/Service"

const data = {
  name:"Analytics & Reporting",
  title:"Analytics & Reporting Services for Data-Driven Insights",
  description:"Leverage expert analytics and reporting services to uncover trends, optimize strategies, and make informed decisions for business success.",
  image:"/analytics-and-reporting.svg",
  text:["We can run all kinds of reports about our support and the network environment. Need to see how many service requests were created for the month? Want to see how many computers are in the environment with certain specs? Reporting allows us to extract data so that you can make informed decisions about IT operations, purchasing, service trends, etc. Let’s take a look at how we can use the data to provide even more effective IT support."],
  url:"/analytics-and-reporting",
  alt:"Analytics & Reporting"
}

const Analytics = () => {
  return (
    <Service data={data} />
  )
}

export default Analytics