import Service from "../../components/Service"

const data = {
  name:"Remote Monitoring & Management",
  title:"Reliable Remote Monitoring & Management Services in LA",
  description:"Ensure your IT systems run seamlessly with expert remote monitoring and management. Proactive solutions to optimize performance and reduce downtime.",
  image:"/remote-monitoring.svg",
  text:["We will proactively monitor your network and everything on it using our Remote Monitoring and Management (RMM) system. We will let you know if something looks out of the ordinary and our Support Team will fix it; because after all, we love this stuff! Hard drive out of space? Internet down? Virus or malware on your PC? No matter what the issue, our system allows us to deliver proactive IT support and save your business time and money. Our system will do its best to resolve the issues by itself using its configured scripts and fixes without even needing our support staff. And, the best part is it’s all included with our monthly services!"],
  url:"/remote-monitoring-and-management",
  alt:"Remote Monitoring and Management"
}

const RemoteMonitoring = () => {
  return (
    <Service data={data} />
  )
}

export default RemoteMonitoring