import Service from "../../components/Service"

const data = {
  name:"Security Awareness Training",
  title:"Security Awareness Training for Safer Workplaces",
  description:"Empower your team with security awareness training. Learn to identify threats, protect sensitive data, and build a culture of cybersecurity.",
  image:"/security-awareness-training.svg",
  text:["60% of Small Businesses fold within six months of a cyber attack. The weakest link in any network environment is and will always be the the end-user. With sophisticated attacks like Ransomware, Phishing & Spearphishing, CEO Fraud, Email Spoofing and Keyloggers, it is imperative that employees are knowledgeable and aware of these attacks.",
        "We have partnered with the industry leader in Security Awareness Training to ensure that all employees become experts in spotting and avoiding these types of cyber attacks. Our security team sends out simulated email phishing campaigns to all employees and then reports on the findings. We then continue to test offenders and assign training to ensure they become more diligent and knowledgeable. We assist our clients with their employee onboarding by assigning training modules as soon as they are hired. Ask us how we can supplement your security profile with Security Awareness Training."
  ],
  url:"/security-awareness-training",
  alt:"Security Awareness Training"
}

const SecurityAwarenessTraining = () => {
  return (
    <Service data={data} />
  )
}

export default SecurityAwarenessTraining