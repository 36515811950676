import { Link } from 'react-router-dom'
import { FaYelp } from "react-icons/fa"
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import './index.css'

const Social = () => {
  return (
    <div className='social-container'>
      <div className='footer-logo'>
        <Link to="/" role="link" aria-label='Home'>
          <img src="/logo-7l-full.webp" alt='7 Layer IT Solutions Logo'/>
        </Link>
      </div>
      <div className='social-links-container'>
        <span>CONNECT WITH US</span>
        <div className='social-links'>
          <Link to="https://www.linkedin.com/company/7lits" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <FaLinkedin className="social-icon" />
          </Link>
          <Link to="https://www.facebook.com/7lits" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebookSquare className="social-icon" />
          </Link>
          <Link to="https://www.instagram.com/7lits" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagramSquare className="social-icon" />
          </Link>
          <Link to="https://www.yelp.com/biz/7-layer-it-solutions-chatsworth" target="_blank" rel="noopener noreferrer" aria-label="Yelp">
            <FaYelp className="yelp-icon" />
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Social