import Service from "../../components/Service"

const data = {
  name:"Managed Security Operations Center",
  title:"Managed Security Operations Center (SOC) Services",
  description:"Protect your business with Managed Security Operations Center (SOC) services. Get 24/7 monitoring, threat detection, and rapid response solutions.",
  image:"/security-operations-center.svg",
  text:["We provide a comprehensive threat detection, prevention and response system which includes 24/7 monitoring and expert analysis to identify and prevent attacks across all attack vectors, proactive detection & prevention, real-time threat investigation, threat hunting, incident response, and log analysis & management. EDR is a great tool by itself but only monitors and protects at the endpoint level. Managed SOC analyzes and responds to threats across all parts of the organization – the endpoints, the company’s network as well as cloud resources. This ensures that we have a comprehensive monitoring tool across the entire client footprint. Managed SOC is quickly becoming a security standard when applying for Cyber Insurance. Additionally, more than ever insurance and audit companies want to see that their customers are able to pull and analyze logs pertaining to possible breaches should they occur. Our security team can upgrade your security posture with our full suite of cyber security solutions. Please contact us for more details!"],
  url:"/managed-security-operations-center",
  alt:"Managed Security Operations Center"
}

const ManagedSecurityOperationsCenter = () => {
  return (
    <Service data={data} />
  )
}

export default ManagedSecurityOperationsCenter