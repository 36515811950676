import Service from "../../components/Service"

const data = {
  name:"Business Continuity & Disaster Recovery",
  title:"Business Continuity & Disaster Recovery Solutions",
  description:"Protect your business with top-notch continuity and disaster recovery solutions. Minimize downtime and safeguard operations during any crisis.",
  image:"/disaster-recovery.svg",
  text:["Your data is the most critical part of your business. So why don’t you care for it that way? Can you really depend on your current backup solution? Are you still running one daily backup? Have you tried to restore it? Is there anyone even checking on your backups? If you had a server or network outage, do you know how long you would really be out of business?",
        "Downtime is not an option for companies today. Different types of issues can occur and can impact your revenue and possibly your business existence. Whether it’s a security breach such as Ransomware or Phishing attack, power outage, fire, or simply a human mistake, these events are unpredictable. The best thing you can do is to BE PREPARED! Preparedness means that you should have a solid BCDR plan that has been tested and that can be activated smoothly. Do you know what your Recovery Point Objective (RPO) and Recovery Time Objective (RTO) times are? If not, you should contact us so we can help you put a comprehensive plan in place.",
        "We image our clients’ servers multiple times per day. We send our backup images to offsite, geo-redundant, encrypted datacenters compliant with the Service Organization Control (SOC 1/ SSAE 16 and SOC 2) reporting standards. If your server fails, we will have you back up in minutes, not days. Our monitoring system will notify us if/when a backup job fails and our technicians will fix it right away. Need a file, folder or server restored, we got it! We promise to take care of your data as if it were our own. Because even if you don’t realize how important your data is, we do!",
        "Our BCDR solutions enable us to bring your network operations up in the cloud in the event of a catastrophic failure and we can do it quickly. If your business can’t afford to turn off its Open sign, we should talk."],
  url:"/business-continuity-and-disaster-recovery",
  alt:"Business Continuity and Disaster Recovery"
}

const BusinessContinuity = () => {
  return (
    <Service data={data} />
  )
}

export default BusinessContinuity