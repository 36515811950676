import { PiPhone } from "react-icons/pi" /* VoIP */
import { BiSupport } from "react-icons/bi" /* Unlimited Technical Support */
import { MdOutlineCloudSync } from "react-icons/md" /* Cloud7 */
import { GiBackwardTime } from "react-icons/gi" /* BCDR */
import { BsPersonGear } from "react-icons/bs" /* On-Site & Network Services */
import { PiGauge } from "react-icons/pi" /* Remote Monitoring */
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined' /* Managed Print Services */

import { FaCrosshairs } from "react-icons/fa6" /* EDR */
import { SiSecurityscorecard } from "react-icons/si" /* Vulnerability Management */
import { FaNetworkWired } from 'react-icons/fa' /* SOC */
import { FaUserShield } from 'react-icons/fa6' /* Security Awareness Training */
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined' /* Penetration Testing */
import { MdOutlineMail } from "react-icons/md";
import { MdOutlineMailLock } from "react-icons/md"; /* Encrypted Email */
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined' /* MDM */

import WifiPasswordOutlinedIcon from '@mui/icons-material/WifiPasswordOutlined' /* Secure Internet */
import CableIcon from '@mui/icons-material/Cable' /* Cabling */
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined' /* Technology Purchasing */
import { AiOutlineAudit } from "react-icons/ai" /* audit */
import { IoAnalyticsSharp } from "react-icons/io5" /* analytics & reporting */

/* import { FiLogIn } from "react-icons/fi" */
import { LiaUserFriendsSolid } from "react-icons/lia"
import { FaWindows } from "react-icons/fa6"
import { AiOutlineBuild } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa6";
import { MdOutlineComment } from "react-icons/md";

export const menuItems = [
    {
        ref: 'managedITServicesMenu',
        title: 'Managed IT Services',
        path: '/managed-it-services',
        aria: 'managed-it-services-menu',
        children: [
            {
                id: 0,
                subtitle: 'Unlimited Technical Support',
                path: '/unlimited-technical-support',
                icon: <BiSupport style={{fontSize:19,marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'unlimited-technical-support',
                ref: 'unlimited-technical-support'
            },
            {
                id: 1,
                subtitle: 'Remote Monitoring & Management',
                path: '/remote-monitoring-and-management',
                icon: <PiGauge style={{fontSize:20,marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'remote-monitoring-and-management',
                ref: 'remote-monitoring-and-management'
            },
            {
                id: 2,
                subtitle: 'On-Site Support & Network Services',
                path: '/on-site-it-support-and-network-services',
                icon: <BsPersonGear style={{fontSize:20,marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'on-site-support-and-network-services',
                ref: 'on-site-support-network-services'
            },
            {
                id: 3,
                subtitle: 'Windows & 3rd Party Patching',
                path: '/windows-updates-and-third-party-patching',
                icon: <FaWindows style={{fontSize:18,marginRight:"0.9rem",opacity:0.7}}/>,
                aria: 'windows-updates-and-third-party-patching',
                ref: 'windows-updates-and-third-party-patching'
            },
            {
                id: 4,
                subtitle: 'Business Technology Audits',
                path: '/business-technology-audits',
                icon: <AiOutlineAudit style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}} />,
                aria: 'business-technology-audits',
                ref: 'business-technology-audits'
            },
            {
                id: 5,
                subtitle: 'Analytics & Reporting',
                path: '/analytics-and-reporting',
                icon: <IoAnalyticsSharp style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}} />,
                aria: 'analytics-and-reporting',
                ref: 'analytics-and-reporting'
            },
        ]
    },
    {
        ref: 'itSolutionsMenu',
        title: 'IT Solutions',
        path: '/it-solutions',
        aria: 'it-solutions-menu',
        children: [
            {
                id: 0,
                subtitle: 'Cloud7 Services',
                path: '/cloud-services',
                icon: <MdOutlineCloudSync style={{fontSize:20, padding:"0", margin:"0rem 0.9rem 0 -0.1rem",opacity:0.7}}/>,
                aria: 'cloud-services',
                ref: 'cloud-services'
            },
            {
                id: 1,
                subtitle: 'Business Continuity & Disaster Recovery',
                path: '/business-continuity-and-disaster-recovery',
                icon: <GiBackwardTime style={{fontSize:21, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'business-continuity-and-disaster-recovery',
                ref: 'business-continuity-and-disaster-recovery'
            },
            {
                id: 2,
                subtitle: 'Voice over Internet Protocol (VoIP)',
                path: '/voip',
                icon: <PiPhone style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'voice-over-ip',
                ref: 'voip'
            },
            {
                id: 3,
                subtitle: 'Managed Print Services (MPS)',
                path: '/managed-print-services',
                icon: <PrintOutlinedIcon style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}} />,
                aria: 'managed-print-services',
                ref: 'managed-print-services'
            },
            {
                id: 4,
                subtitle: 'Internet Connectivity',
                path: '/internet-connectivity',
                icon: <WifiPasswordOutlinedIcon style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'internet-connectivity',
                ref: 'internet-connectivity'
            },
            {
                id: 5,
                subtitle: 'Structured Cabling & Electrical',
                path: '/structured-cabling-and-electrical-services',
                icon: <CableIcon style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'structured-cabling-and-electrical',
                ref: 'structured-cabling-and-electrical'
            },
            {
                id: 6,
                subtitle: 'Technology Procurement',
                path: '/technology-procurement-services',
                icon: <DevicesOutlinedIcon style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'technology-procurement',
                ref: 'technology-procurement'
            }       
        ]
    },
    {
        ref: 'securityServicesMenu',
        title: 'Network & Cybersecurity',
        path: '/network-and-cybersecurity',
        aria: 'network-and-cyber-security-menu',
        children: [
            {
                id: 0,
                subtitle: 'Endpoint Detection & Response (EDR)',
                path: '/endpoint-threat-detection-and-response',
                icon: <FaCrosshairs style={{fontSize:19, marginRight:"0.8rem",opacity:0.7}} />,
                aria: 'endpoint-detection-and-response',
                ref: 'endpoint-detection-and-response'
            },
            {
                id: 1,
                subtitle: 'Managed Security Operations Center (SOC)',
                path: '/managed-security-operations-center',
                icon: <SecurityOutlinedIcon style={{fontSize:18, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'managed-security-operations-center',
                ref: 'managed-security-operations-center'
            },
            {
                id: 2,
                subtitle: 'Internal & External Vulnerability Management',
                path: '/vulnerability-management',
                icon: <SiSecurityscorecard style={{fontSize:18, marginRight:"0.9rem",opacity:0.7}} />,
                aria: 'internal-and-external-vulnerability-management',
                ref: 'vulnerability-management'
            },
            {
                id: 3,
                subtitle: 'Virtual Network Penetration Testing',
                path: '/virtual-network-penetration-testing',
                icon: <FaNetworkWired style={{fontSize:19, marginRight:"0.8rem",opacity:0.7}} />,
                aria: 'virtual-network-penetration-testing',
                ref: 'virtual-network-penetration-testing'
            },
            {
                id: 4,
                subtitle: 'Security Awareness Training',
                path: '/security-awareness-training',
                icon: <FaUserShield style={{fontSize:19, marginLeft:"0.1rem", marginRight:"0.7rem",opacity:0.7}}/>,
                aria: 'security-awareness-training',
                ref: 'security-awareness-training'
            },
            {
                id: 5,
                subtitle: 'Mobile Device Management (MDM)',
                path: '/mobile-device-management',
                icon: <PhoneIphoneOutlinedIcon style={{fontSize:20, margin:"-0.15rem 0.7rem 0rem 0rem",opacity:0.7}}/>,
                aria: 'mobile-device-management',
                ref: 'mobile-device-management'
            },
            {
                id: 6,
                subtitle: 'Encrypted Email',
                path: '/encrypted-email',
                icon: <MdOutlineMailLock style={{fontSize:19, marginRight:"0.75rem",opacity:0.7}}/>,
                aria: 'encrypted-email',
                ref: 'encrypted-email'
            },
            {
                id: 7,
                subtitle: 'Advanced Email Threat Protection',
                path: '/advanced-email-threat-protection',
                icon: <MdOutlineMail style={{fontSize:20, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'advanced-email-threat-protection',
                ref: 'advanced-email-threat-protection'
            },    
        ]
    },
    {
        ref: 'companyMenu',
        title: 'Company',
        path: '/our-story',
        aria: 'company',
        children: [
            {
                id: 0,
                subtitle: 'Our Story',
                path: '/our-story',
                icon: <LiaUserFriendsSolid style={{fontSize:20, marginRight:"0.7rem",opacity:0.7}}/>,
                aria: 'our-story',
                ref: 'our-story'
            },
            {
                id: 1,
                subtitle: 'Our Mission',
                path: '/our-mission',
                icon: <AiOutlineBuild style={{fontSize:20, marginRight:"0.7rem",opacity:0.7}}/>,
                aria: 'our-mission',
                ref: 'our-mission'
            },
            {
                id: 2,
                subtitle: 'Careers',
                path: '/careers',
                icon: <FaRegUser style={{fontSize:18, margin:"0 0.7rem 0 0.1rem",opacity:0.7}}/>,
                aria: 'careers',
                ref: 'careers'
            }
        ]
    },
    {
        ref: 'clientResourcesMenu',
        title: 'Client Resources',
        path: '/support',
        aria: 'client-resources',
        children: [
            {
                id: 1,
                subtitle: 'Get Support Now',
                path: '/support',
                icon: <BiSupport style={{fontSize:19,marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'support',
                ref: 'support'
            },
            {
                id: 2,
                subtitle: 'Blog',
                path: '/blog',
                icon: <MdOutlineComment style={{fontSize:18, margin:"0 0.7rem 0 0.1rem",opacity:0.7}}/>,
                aria: 'blog',
                ref: 'blog'
            }
            /* ,
            {
                id: 1,
                subtitle: 'Client Portal',
                path: 'https://7lits.ITClientPortal.com/',
                icon: <FiLogIn style={{fontSize:18, marginRight:"0.8rem",opacity:0.7}}/>,
                aria: 'client-portal',
                ref: 'client-portal'
            } */
        ]
    }
]