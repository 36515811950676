import Service from "../../components/Service"

const data = {
  name:"Mobile Device Management (MDM)",
  title:"Mobile Device Management (MDM) Solutions for Businesses",
  description:"Secure and manage your workforce's mobile devices with expert MDM solutions. Enhance productivity while ensuring data security and compliance.",
  image:"/mobile-device-management.svg",
  text:["With businesses becoming more mobile every day, the importance of having business application access on mobile devices is more important than ever. However, with that increased access comes the responsibility to ensure that the mobile devices are secure and your IT Department has a pulse on them. We have non-invasive management tools that allow us to remotely setup, secure and manage any type of mobile device or tablet. We have the ability to remotely deploy wifi networks, security policies, email accounts, business applications, etc. and we can do it all from our management console, without ever physically touching the devices. Ask us how we can secure your mobile devices so that you can sleep better at night!"],
  url:"/mobile-device-management",
  alt:"Mobile Device Management"
}

const MobileDeviceManagement = () => {
  return (
    <Service data={data} />
  )
}

export default MobileDeviceManagement