import Service from "../../components/Service"

const name = "Cloud Services"
const description = "Choose Cloud7 Services for reliable cloud solutions in Los Angeles, CA. Secure, scalable, and tailored cloud management for your business needs."
const data = {
  name:name,
  title:"Cloud7 Services: Reliable Cloud Solutions in Los Angeles, CA",
  description:description,
  image:"/cloud-solutions.svg",
  text:["Moving applications and infrastructure to the Cloud has become very popular. There are many factors to consider when deciding if it is the right move for your company – private, public, vendor, cost, location, specifications, uptime guarantees, etc. We have leveraged our resources to create our own Private Cloud that we call Cloud7.",
        "With over ten internet service providers, redundant power on separate physical hardware all the way down to the server rack, state-of-the art cooling, power generators, and many levels of security, this facility can securely house and host your data as close to 100% as you can get! Ask us how we can help you migrate your servers and data over with almost zero downtime!",
        "Have multiple sites and looking for centralized application and data access? Don’t want the headaches and costs of purchasing and configuring infrastructure hardware and storing at your office? Let us connect you into our Cloud! Let us assess your technology and see if it is the best move for your business."],
  url:"/cloud-services",
  alt:name
}

const CloudServices = () => {
  return (
    <Service data={data} />
  )
}

export default CloudServices