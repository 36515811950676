import Service from "../../components/Service"

const data = {
  name:"Advanced Email Threat Protection",
  title:"Advanced Email Threat Protection for Maximum Security",
  description:"Guard your business against advanced email threats. Benefit from proactive protection, phishing defense, and enhanced email security solutions.",
  image:"/advanced-email-filtering.webp",
  text:["Over 90% of all cyberattacks originate with email. With over 99.9% catch-rate and extremely low false-positives, our Advanced Email Threat Protection will ensure that the bad emails stay out, while the valid emails make it to your inbox. We have a 24/7/365 Live Threat Analysis Team constantly identifying, updating and blocking new threats. Any link within an email is analyzed before opening to ensure links are valid and known to be good. All emails are scanned for illegitimate attachments which are automatically removed and quarantined into a cloud-based portal for review. We can retract an email from an entire Microsoft Office 365 organization with one click. Our team of experts can implement this service with no downtime so that you never miss an email!"],
  url:"/advanced-email-threat-protection",
  alt:"Advanced Email Threat Protection"
}

const EmailThreatDetection = () => {
  return (
    <Service data={data} />
  )
}

export default EmailThreatDetection