import PropTypes from 'prop-types'
import Solutions from '../Solutions'
import './index.css'

const Partners = ({ isDesktop }) => {
  return (
    <div style={{margin:0, padding:isDesktop ? "0 3rem": "0 1.65rem", boxSizing:"border-box", overflow:"hidden", maxWidth:"100%"}}>
      <div style={{marginTop:"4rem", textAlign:"center"}}>
        <h2 style={{fontSize:isDesktop ? 32 : 24}}>Partners</h2>
        <div style={{padding:"1.2rem 3rem"}}>
          <h3 style={{fontSize:"1.25rem", fontWeight:700}}>
            We only partner with the best technology companies for two reasons,{isDesktop && <br></br>}to bring our clients the best service possible and because we like to sleep at night.
          </h3>
        </div>
      </div>
      <div style={{margin:isDesktop ? "1rem 5rem" : "1rem", maxWidth:"100%", textAlign:"center"}}>
        <div className='partner-top'>
            <img id='microsoft' src='/partners/microsoft-partner.webp' alt='Microsoft Partner Logo'/>
            <img id='datto' src='partners/datto-platinum-partner.svg' alt='Datto Platinum Partner Badge'/>
            <img id='dell' src='/partners/dell-technologies-authorized-partner.webp' alt='Dell Authorized Partner'/>
        </div>
        <div className="partner">
          <img src='/partners/logo-hp.webp' alt='HP Partner Logo'/>
          <img src='/partners/logo-cisco-meraki.webp' alt='Cisco Meraki Partner Logo' />
          <img src='/partners/logo-barracuda.webp' alt='Barracuda Partner Logo'/>
          <img src='/partners/logo-goto.webp' alt='GoTo Partner Logo'/>
          <img src='/partners/logo-knowbe4.webp' alt='KnowBe4 Partner Logo'/>
          <img src='/partners/logo-dh.webp' alt='DH Partner Logo'/> 
          <img src='/partners/logo-vmware.webp' alt='VM Ware Partner Logo'/>
          <img src='/partners/logo-ergotron.webp' alt='Ergotron Partner Logo'/>      
          <img src='/partners/logo-apc.webp' alt='APC Partner Logo' />
          <img src='/partners/logo-td-synnex.webp' alt='TD Synnex Partner Logo'/>
        </div>
        <Solutions />
      </div>
    </div>
    
  )
}

Partners.propTypes = {
  isDesktop: PropTypes.bool.isRequired
}

export default Partners