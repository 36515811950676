import { Link } from 'react-router-dom'
import FadeIn from '../../components/FadeIn'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import { blogList } from '../../static/blogList'
import './index.css'

const BlogHome = (props) => {
  const blogs = Object.values(blogList)
  return (
    <div className='blog-page'>
      <div>
        <div className='blog-page-title'>
          <h1>Latest News</h1>
        </div>
        <div className='featured-blog'>
          <Link to={"/blog/" + blogs[0].path} aria-label={blogs[0].title}>
            <div className='featured-image-container'><img src={blogs[0].image} /></div>
            <div className='blog-text'>
              <h2 className='featured-blog-title'>{blogs[0].title}</h2>
              <p className='blog-date'>{blogs[0].date}</p>
            </div>
          </Link>
        </div> 
       <div className='blog-item-container'>
          {blogs.slice(1).map((blog, blogIndex) => {
            let index = parseInt(blogIndex, 10) + 1
            return (
              <FadeIn key={index}>
                <div className='blog-item' >
                  <Link to={"/blog/" + blog.path} aria-label={blog.title}>
                    <div className='image-container'>  
                      <img src={blog.image} />
                    </div>
                    <div className='blog-text'>
                      <h2 className='blog-title'>{blog.title}</h2>
                      <p className='blog-date'>{blog.date}</p>
                    </div>
                  </Link>
                </div>
              </FadeIn>
            )})}
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

export default BlogHome