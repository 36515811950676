import Service from "../../components/Service"

const data = {
  name:"Internet Connectivity",
  title:"Reliable Internet Connectivity for Business Growth",
  description:"Ensure fast and secure internet connectivity for your business. Reliable solutions to enhance productivity and support your operations efficiently.",
  image:"/secure-business-internet.svg",
  text:["Are you moving offices or need additional bandwidth but don’t want to waste a bunch of time figuring out what to get and who to get it from? With all the connectivity options and technologies available today, deciding which is the best option for your business can be very difficult and confusing. We partner with one of the country’s largest technology Master Agents. This allows us to source pricing on any internet technology, from any vendor and at any location. With our many years of experience, we can tell you which solution is best for your business. You will feel confident that you are getting the best solution at the best price."],
  url:"/internet-connectivity",
  alt:"Internet Connectivity"
}

const InternetConnectivity = () => {
  return (
    <Service data={data} />
  )
}

export default InternetConnectivity