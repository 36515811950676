import { Link } from 'react-router-dom'
/*import { useMediaQuery, useTheme } from '@mui/material'
import Level1Support from './level-1-support-analyst'
import Level2Support from './level-2-support-analyst' 
import { useReducer } from 'react' */
import ScrollToTop from '../../components/buttons/ScrollToTop'
import Seo from '../../components/Seo'

const Careers = (props) => {
/*   const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const [expanded, dispatch] = useReducer(setExpanded, false);

  const handleChange = (event, panel) => {
    dispatch({
      name: panel
    })
  } */

  return (
    <div className='page'>
      <Seo 
        title="Careers at 7 Layer IT Solutions"
        description="Great Place to Work Certified. Work collaboratively with a dynamic team to create cutting-edge IT solutions."
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/careers"
      />
      <div className='page-container'>
        <div className='page-title'>
          <div className='careers-title'>
            <img src='/7-layer-it-solutions-blk.webp' />
            <h1>Careers</h1>
          </div>
        </div>
        <div className='content-container'>
          <div className='content-section'>
            <p>One of the reasons we started this company was to create a family-like work environment, where everyone has a voice. We work hard, but we like to have fun as well. Our employees will always be very important to us and we always try to implement programs, events, and initiatives to show our gratitude to all of you.</p>
          </div>
        </div>
        
        <div className='content-container'>
          <div className='content-section'>
            <div className='roles-container'>
              <h2>Roles</h2>
              <div className='content-section'>
                <p>There are no open roles at this time.</p>
              </div>
            </div>
            {/* <Level1Support isDesktop={isDesktop} expanded={expanded} onAccordionChange={handleChange}/>
            <Level2Support isDesktop={isDesktop} expanded={expanded} onAccordionChange={handleChange}/> */}
          </div>
        </div>
        <div className='content-container'>
        <div className='careers-image-container'>
            <Link target='_blank' to='https://www.greatplacetowork.com/certified-company/7080255'>
              <img className='great-place-to-work-img' alt='Great Place To Work Certification' src="/We're_Certified!_US.png" />
            </Link>         
          <div>
            <img className='company-party-img' src='/company_image_3.jpg' alt='7 Layer IT Solutions Holiday Party' />
          </div>
        </div>
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

/* const setExpanded = (expanded, newExpanded) => {
  return newExpanded.name === expanded ? false : newExpanded.name
}
 */
export default Careers