import { IoMdCloudOutline } from "react-icons/io";
import { FaDownload } from "react-icons/fa6"
import { FaPhoneVolume } from "react-icons/fa6"
import { FaPrint } from "react-icons/fa6"
import CableIcon from '@mui/icons-material/Cable'
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined'
import WifiPasswordOutlinedIcon from '@mui/icons-material/WifiPasswordOutlined'
import Seo from '../../components/Seo'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import ServiceNavMenu from '../../components/ServiceNavMenu'

const data = [
  {
    "name": "Managed Cloud Services",
    "url": "/cloud-services",
    "icon": <IoMdCloudOutline fontSize={33}/>
  },
  {
    "name": "Business Continuity Disaster Recovery",
    "url": "/business-continuity-and-disaster-recovery",
    "icon": <FaDownload fontSize={30}/>
  },
  {
    "name": "Voice over Internet Protocol (VoIP)",
    "url": "/voip",
    "icon": <FaPhoneVolume fontSize={28} />
  },
  {
    "name": "Managed Print Services (MPS)",
    "url": "/managed-print-services",
    "icon": <FaPrint fontSize={30}/>
  },
  {
    "name": "Internet Connectivity",
    "url": "/internet-connectivity",
    "icon": <WifiPasswordOutlinedIcon style={{fontSize:32}}/>
  },
  {
    "name": "Structured Cabling & Electrical",
    "url": "/structured-cabling-and-electrical-services",
    "icon": <CableIcon style={{fontSize:32}}/>
  },
  {
    "name": "Technology Procurement",
    "url": "/technology-procurement-services",
    "icon": <DevicesOutlinedIcon style={{fontSize:32}}/>
  },
]

const ITSolutions = (props) => {
  return (
    <div className='page'>
      <Seo 
        title="Top IT Solutions Company in the USA"
        description="Partner with a leading IT solutions company to transform your business. Explore innovative tech services designed to meet your unique needs."
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/it-solutions"
      />
      <div className='page-container'>
        <div className='page-title'>
          <h1>IT Solutions</h1>
        </div>
        <div className='contents'>
          <p> We strive to provide our clients with exceptional and cutting-edge IT solutions at affordable prices. We will design, implement and manage secure, reliable and cost-effective networks whether the client has two or 2,000 users. We will always maintain that personal connection with our clients and our dependability will be second to none.</p>
        </div>
      </div>
      <ServiceNavMenu data={data} />
      <ScrollToTop {...props} />
    </div>
    )
  }


export default ITSolutions