import ScrollToTop from '../../components/buttons/ScrollToTop'
import Seo from '../../components/Seo'

const OurStory = (props) => {
  return (
    <div className='page'>
      <Seo 
        title="Our Story"
        description=""
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/our-story"
      />
      <div className='page-container'>
        <div className='page-title'>
          <h1>Our Story</h1>
        </div>
        <div className='content-container'>
          <div className='content-section'>
            <p>Founders Angel Cifuentes and Michael Sagoian have over 50 years of combined experience in the technology industry. Their vision for 7 Layer IT Solutions started in 2007 in a small break/fix shop in Encino, CA. Together Angel and Michael grew their connections in their community through their trusted expertise. After many long hours of dedication and sacrifice, they launched a business of their own, 7 Layer IT Solutions.</p>
            <p>Through the years, the business has grown to support various industries and thousands of professionals in legal firms, healthcare clinics, non-profits, small businesses and much more.</p>
          </div>
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

export default OurStory