import { clientList } from '../../static/clientList'
import './index.css'

const Clients = () => {
  return (
    <div className='client-container'>
      <div className='section-header'>
        <h2>Clients</h2>
        <div className='clients'>
          <h3>
            We provide exceptional, cutting-edge IT solutions at affordable prices.<br></br>
            Whether you have two or 2,000 users, our dependability is second to none, just ask our clients.
          </h3>
        </div>
      </div>
        <div className='logos'>
          <div className='logos-slide'>
            {clientList.map((clientInfo, index) => {
              return (
                <img key={index} src={clientInfo.image} id={clientInfo.id} alt={clientInfo.label + ' Logo'}/>
              )
            })}
          </div>
          <div className='logos-slide'>
            {clientList.map((clientInfo, index) => {
              return (
                <img key={index} src={clientInfo.image} id={clientInfo.id} alt={clientInfo.label + ' Logo'}/>
              )
            })}
          </div>
        </div>
    </div>
  )
}

export default Clients