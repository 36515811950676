
import { Link, useParams } from 'react-router-dom'
import { blogList } from '../../static/blogList'
import LatestNews from '../LatestNews'
import ScrollToTop from '../buttons/ScrollToTop'
import Seo from '../Seo'
import PageNotFound from '../PageNotFound'
import './index.css'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { FaFacebook } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { FaWhatsapp } from "react-icons/fa"
import { IoIosMail } from "react-icons/io"
import PropTypes from 'prop-types'

const Blog = (props, { pathname }) => {
  const params = useParams()

  if (!Object.hasOwn(blogList, params.path)) {
    return (<PageNotFound location={pathname}/>)
  } else {
    const blogEntry = blogList[params.path]
    const url = "https://7lits.com/blog/" + params.path
    return (
      <div className='blog-entry-page'>
        <Seo 
          title={blogEntry.meta.title}
          description={blogEntry.meta.description}
          type="website"
          name="7 Layer IT Solutions"
          url={url}
          jsonData={blogEntry.json}
        />
        <div className='blog-entry-container'>
          <div className='blog-header'>
            <p>{blogEntry.date}</p>
            <h1>{blogEntry.title}</h1>
            <h2>{blogEntry.description}</h2>
            <div className='share-buttons'>
              <FacebookShareButton url={url}>
                <FaFacebook size={26} />
              </FacebookShareButton>
              <TwitterShareButton url={url}>
                <FaXTwitter size={26} />
              </TwitterShareButton>
              <WhatsappShareButton url={url}>
                <FaWhatsapp size={28} />
              </WhatsappShareButton>
              <Link target='_blank' to={'mailto:?body=Check%20out%20this%20article%20from%207%20Layer%20IT%20Solutions:%0A%0A' + url}>
                <IoIosMail size={34}/>
              </Link>
            </div>
          </div>
          <div className='blog-image-container'>
            <img src={blogEntry.image} />
          </div>
          <div style={{margin:"2rem"}}>
            {Array.isArray(blogEntry.text)
            ? blogEntry.text.map((text, textIndex) => {
                return (
                  <div className='blog-section' key={textIndex}>
                    {text}
                  </div>
                )
              })
            : blogEntry.text}
            <LatestNews />
          </div>
        </div>
        <ScrollToTop {...props} />
      </div>
    )
  }
}

Blog.propTypes = {
  location: PropTypes.string.isRequired
}

export default Blog