import ScrollToTop from '../../components/buttons/ScrollToTop'
import Seo from '../../components/Seo'

const OurMission = (props) => {
  return (
    <div className='page'>
      <Seo 
        title="Our Mission"
        description=""
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/our-mission"
      />
      <div className='page-container'>
        <div className='page-title'>
          <h1>Our Mission</h1>
        </div>
        <div className='content-container'>
          <div className='content-section'>
            <p>We will strive to provide our clients with exceptional and cutting-edge IT solutions and support at affordable prices, no matter what. We will design, implement and manage secure, reliable and cost-effective networks whether the client has two or 2,000 users. We will always maintain that personal connection with our clients and our dependability will be second to none.</p>
          </div>
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

export default OurMission