import Service from "../../components/Service"

const data = {
  name:"Internal & External Vulnerability Management",
  title:"Internal & External Vulnerability Management Services",
  description:"Secure your business with comprehensive internal and external vulnerability management. Detect, assess, and resolve security threats proactively.",
  image:"/vulnerability-management.svg",
  text:["Vulnerability Scanning has become an essential tool in evaluating the overall We have partnered with VulScan to provide cost-effective internal and external network scanning to satisfy one of The National Institute of Standards and Technology’s most important Cybersecurity Framework recommendations. Once installed, our network scanners will scan the internal network for issues such as operating system and security misconfigurations, unauthorized access to systems, assess practices and polices related to both private and public networks to detect known anomalies, application configuration settings, application patch history and update recommendations, wireless networks, database configurations & settings, and more. This system will generate action items for our support team to remediate and resolve to ensure the network maintains the highest security standards possible.",
        "The external scanning will monitor the network security and health from outside the organization and target areas such as the company’s firewall, port configuration, web applications, networks, etc. External scanning is as important as internal scanning to ensure that attackers have as few options as possible to penetrate the network. With continuous system vulnerability scanning, this ensures that if/when any network breach occurs, it is located and remediated so that an attacker cannot gain a foothold into the network, dwell and gather critical network information in order to initiate a crippling security attack. In addition, VulScan satisfies compliance for a number of security standards such as GDPR, HIPAA and PCI DSS."
  ],
  url:"/vulnerability-management",
  alt:"Internal and External Vulnerability Management"
}

const VulnerabilityManagement = () => {
  return (
    <Service data={data} />
  )
}

export default VulnerabilityManagement