import { Link } from 'react-router-dom'
import CopyrightIcon from '@mui/icons-material/Copyright'
import './index.css'

const Legal = () => {
  return (
    <div className='legal-container'>
      <div className='legal-content'>
        <div className='copyright'>
          <p>7 Layer IT Solutions, Inc.</p>
          <CopyrightIcon className='copyright-icon' fontSize='small'/>
          <p>2025 All Rights Reserved</p>
        </div>
        <div className='policies'>
          <p><Link to='/terms' aria-label='Terms of Use'>Terms of Use</Link></p>
          <p><Link to='/privacy' aria-label='Privacy Policy'>Privacy Policy</Link></p>
          <p><Link to='/cookies' aria-label='Cookies Policy'>Cookies Policy</Link></p>
        </div>  
      </div>
    </div>
  )
}

export default Legal