import { Link } from "react-router-dom"
import FadeIn from "../FadeIn"
import { blogList } from "../../static/blogList"
import "./index.css"

const LatestNews = () => {
  const newsList = Object.values(blogList).slice(0,3)
  return (
    <div className='latest_news'>
    <h3>Latest News</h3>
    {newsList.map((blog, blogIndex) => {
      return(
        <FadeIn key={blogIndex}>
          <Link to={"/blog/" + blog.path} >
            <div className='news_container' >
              <img src={blog.image} />
            <div className='news_item' key={blogIndex}>
              <h4>{blog.title}</h4>
              <h5 className='news-item-date'>{blog.date}</h5>
            </div>
          </div>
          {newsList.length > 1 && blogIndex < newsList.length-1 ? <hr></hr> : ''}
          </Link>
        </FadeIn>
      )
    })}
  </div>
  )
}

export default LatestNews