import Seo from '../../components/Seo'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import ServiceNavMenu from '../../components/ServiceNavMenu'
import { AiOutlineAudit } from "react-icons/ai" /* audit */
import { IoAnalyticsSharp } from "react-icons/io5" /* analytics & reporting */
import { FaWindows } from "react-icons/fa6"
import { BiSupport } from "react-icons/bi" /* Unlimited Technical Support */
import { BsPersonGear } from "react-icons/bs" /* On-Site & Network Services */
import { PiGauge } from "react-icons/pi" /* Remote Monitoring */

const data = [
  {
    "name": "Unlimited Technical Support",
    "url": "/unlimited-technical-support",
    "icon": <BiSupport fontSize={32} />
  },
  {
    "name": "Remote Monitoring & Management",
    "url": "/remote-monitoring-and-management",
    "icon": <PiGauge fontSize={32} />
  },
  {
    "name": "On-Site IT & Network Services",
    "url": "/on-site-it-support-and-network-services",
    "icon": <BsPersonGear fontSize={32} />
  },
  {
    "name": "Windows Patching & Updates",
    "url": "/windows-updates-and-third-party-patching",
    "icon": <FaWindows fontSize={32}/>
  },
  {
    "name": "Business Technology Audits",
    "url": "/business-technology-audits",
    "icon": <AiOutlineAudit style={{fontSize:32}}/>
  },
  {
    "name": "Analytics & Reporting",
    "url": "/analytics-and-reporting",
    "icon": <IoAnalyticsSharp style={{fontSize:32}}/>
  }
]

const ManagedServices = (props) => {
  return (
    <div className='page'>
      <Seo 
        title="Best IT Managed Services Near Me - Los Angeles, CA"
        description="Find the best IT managed services near you in Los Angeles, CA. Get expert tech solutions and support tailored to your business needs today!"
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/managed-it-services"
      />
      <div className='page-container'>
        <div className='page-title'>
          <h1>Managed IT Services</h1>
        </div>
        <div className='contents'>
          <p>
              Are you tired of fluctuating IT bills every month?<br></br>
              Tired of being charged every time you have an IT issue?<br></br>
              Tired of having to tell your IT company there is an issue instead of them already knowing?<br></br>
              Do you want a trusted IT firm you can call anytime for IT consulting without being charged?<br></br>
              <br></br>
              At 7 Layer IT Solutions, we charge a fixed, monthly fee and cover everything. We want to be your trusted IT resource. We’ll handle everything IT-related so you can focus on your business.<br></br><br></br>You need an IT company that cares about your business, learns your business processes and figures out ways to enable your technology to actually make you money. Let’s talk and see how we can bring value to your business.
          </p>
        </div>
      </div>
      <ServiceNavMenu data={data} />
      <ScrollToTop {...props} />
    </div>
    )
  }


export default ManagedServices