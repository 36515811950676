import Service from "../../components/Service"

const data = {
  name:"On-Site Support & Network Services",
  title:"Expert On-Site IT & Network Services for Businesses",
  description:"Get professional on-site IT and network services to support your business. Reliable solutions for setup, troubleshooting, and maintenance.",
  image:"/on-site-support.svg",
  text:["We think of ourselves as your IT Department, not an outsourced company that is disengaged from your business. Our experienced team of professional, patient and courteous technicians and engineers love technology. We will learn your business and processes to provide the best support possible. It is our job to make your lives easier when it comes to technology. We can be nosy when it comes to our clients’ operations because if we don’t know how they do business, we can’t provide the best technology advice."],
  url:"/on-site-it-support-and-network-services",
  alt:"On-Site Support and Network Services"
}

const OnSite = () => {
  return (
    <Service data={data} />
  )
}

export default OnSite