import { Route, Routes, useLocation } from "react-router-dom"
import { ColorModeContext, useMode } from "../../theme.js"
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import { useEffect, useReducer } from "react"
import Menu from "../Menu/index.jsx"
import MobileMenu from "../MobileMenu"
import Footer from "../Footer"

import Home from '../../scenes/home/index.jsx'
import OurStory from '../../scenes/our-story/index.jsx'
import Support from "../../scenes/support/index.jsx"
import Contact from "../../scenes/contact/index.jsx"
import OurMission from "../../scenes/our-mission/index.jsx"
import Careers from "../../scenes/careers/index.jsx"
import PageNotFound from "../PageNotFound/index.jsx"
import TermsOfUse from "../../scenes/terms/index.jsx"
import PrivacyPolicy from "../../scenes/privacy/index.jsx"
import Cookies from "../../scenes/cookies/index.jsx"

/* Blog */
import BlogHome from "../../scenes/blog/index.jsx"
import Blog from "../Blog"

/* IT Solutions */
import ITSolutions from "../../scenes/it-solutions/index.jsx"
import CloudServices from "../../scenes/it-solutions/01-cloud.jsx"
import BusinessContinuity from "../../scenes/it-solutions/02-bcdr.jsx"
import VoiceOverInternetProtocol from "../../scenes/it-solutions/03-voip.jsx"
import ManagedPrintServices from "../../scenes/it-solutions/04-print.jsx"
import InternetConnectivity from "../../scenes/it-solutions/05-internet.jsx"
import StructuredCablingServices from "../../scenes/it-solutions/06-cabling.jsx"
import TechnologyProcurement from "../../scenes/it-solutions/07-procurement.jsx"

/* Managed IT Services */
import ManagedServices from '../../scenes/managed-it-services/index.jsx'
import UnlimitedTechnicalSupport from "../../scenes/managed-it-services/01-unlimited.jsx"
import RemoteMonitoring from "../../scenes/managed-it-services/02-remote.jsx"
import OnSite from "../../scenes/managed-it-services/03-onsite.jsx"
import WindowsPatching from "../../scenes/managed-it-services/04-patching.jsx"
import BusinessTechnologyAudit from "../../scenes/managed-it-services/05-audit.jsx"
import Analytics from "../../scenes/managed-it-services/06-analytics.jsx"

/* Network & Cybersecurity */
import Cybersecurity from "../../scenes/network-and-cybersecurity/index.jsx"
import EndpointDetectionResponse from "../../scenes/network-and-cybersecurity/01-edr.jsx"
import ManagedSecurityOperationsCenter from "../../scenes/network-and-cybersecurity/02-soc.jsx"
import VulnerabilityManagement from "../../scenes/network-and-cybersecurity/03-vulnerability.jsx"
import PenetrationTesting from "../../scenes/network-and-cybersecurity/04-penetration.jsx"
import SecurityAwarenessTraining from "../../scenes/network-and-cybersecurity/05-awareness.jsx"
import MobileDeviceManagement from "../../scenes/network-and-cybersecurity/06-mdm.jsx"
import EncryptedEmail from "../../scenes/network-and-cybersecurity/07-encrypted.jsx"
import EmailThreatDetection from "../../scenes/network-and-cybersecurity/08-email.jsx"

const App = () => {

  const [theme, colorMode] = useMode()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  
  const [formState, dispatch] = useReducer(setState, '')

  const setFormState = (type) => {
    dispatch({type})
  }

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== '/contact') {
      setFormState('')
    }
  }, [pathname])

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <div id="back-to-top-anchor"></div>
        {isDesktop ? <Menu onChangeFormState={setFormState} /> : <MobileMenu onChangeFormState={setFormState} />}
        <main id='main' className="content">
          <Routes>
            {/* GENERAL */}
            <Route index element={<Home />} />
              <Route path="our-mission" element={<OurMission />} />
              <Route path="our-story" element={<OurStory />} />
              <Route path="contact" element={<Contact formState={formState} onChangeFormState={setFormState} />} />
              <Route path="careers" element={<Careers />} />
              <Route path="support" element={<Support />} />
              <Route path="terms" element={<TermsOfUse />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="cookies" element={<Cookies />} />

            {/* IT Solutions */}
              <Route path="it-solutions" element={<ITSolutions />} />
              <Route path="cloud-services" element={<CloudServices />} />
              <Route path="business-continuity-and-disaster-recovery" element={<BusinessContinuity />} />
              <Route path="voip" element={<VoiceOverInternetProtocol />} />
              <Route path="managed-print-services" element={<ManagedPrintServices />} />
              <Route path="internet-connectivity" element={<InternetConnectivity />} />
              <Route path="structured-cabling-and-electrical-services" element={<StructuredCablingServices />} />
              <Route path="technology-procurement-services" element={<TechnologyProcurement />} />

              {/* Managed IT Services */}
              <Route path="/managed-it-services" element={<ManagedServices />} />
              <Route path="/unlimited-technical-support" element={<UnlimitedTechnicalSupport />} />
              <Route path="/remote-monitoring-and-management" element={<RemoteMonitoring />} />
              <Route path="/on-site-it-support-and-network-services" element={<OnSite/>}/>
              <Route path="/windows-updates-and-third-party-patching" element={<WindowsPatching />} />
              <Route path="/business-technology-audits" element={<BusinessTechnologyAudit />} />
              <Route path="/analytics-and-reporting" element={<Analytics />} />

              {/* Network & Cybersecurity */}
              <Route path="network-and-cybersecurity" element={<Cybersecurity />} />
              <Route path="endpoint-threat-detection-and-response" element={<EndpointDetectionResponse />} />
              <Route path="managed-security-operations-center" element={<ManagedSecurityOperationsCenter />} />
              <Route path="vulnerability-management" element={<VulnerabilityManagement />} />
              <Route path="virtual-network-penetration-testing" element={<PenetrationTesting />} />
              <Route path="security-awareness-training" element={<SecurityAwarenessTraining />} />
              <Route path="mobile-device-management" element={<MobileDeviceManagement />} />
              <Route path="encrypted-email" element={<EncryptedEmail />} />
              <Route path="advanced-email-threat-protection" element={<EmailThreatDetection />} />

              {/* Blog */}
              <Route path="blog">
                <Route index element={<BlogHome />} />
                <Route path=':path' element={<Blog location={pathname} />} />
              </Route>
            

            {/* Routing */}
            <Route path="/404" element={ <PageNotFound location={pathname} /> } />
            <Route path="*" element={ <PageNotFound location={pathname} />} />
          </Routes>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

const setState = (formState, action) => {
  if (action.type === 'error') {
    return 'Error'
  } else if (action.type === 'confirm') {
    return 'Confirm'
  } else {
    return ''
  }
}

export default App
