import Service from "../../components/Service"

const data = {
  name:"Managed Print Services (MPS)",
  title:"Managed Print Services (MPS) For Business Operations",
  description:"Optimize your print management with Managed Print Services (MPS). Cut costs, improve efficiency, and enhance security for all your printing needs.",
  image:"/managed-print-services.svg",
  text:["Printing, copying, faxing, desktop printers, multifunction (MFP) devices, scanners, paper, ink, toner, supplies and support can cost up to 15% of a company’s annual spending. Consolidating those services into a single vendor can save your company around 30% and remove all the headaches of managing that part of your business. Our Managed Print Services (MPS) partners can provide solutions with all the major copy and print companies in the industry such as HP, Xerox, Brother, Konica Minolta and Ricoh. Let us assess your usage and current devices to come up with a solution that will save you money and consolidate the equipment and services."],
  url:"/managed-print-services",
  alt:"Managed Print Services"
}

const ManagedPrintServices = () => {
  return (
    <Service data={data} />
  )
}

export default ManagedPrintServices