import { useEffect, useState } from 'react'
import { Typography, useMediaQuery, useTheme } from "@mui/material"
import { Link } from 'react-router-dom'
import { tokens } from "../../theme"
import ContactForm from "../../components/ContactForm"
import FormError from '../../components/FormError'
import FormConfirm from "../../components/FormConfirm"
import PropTypes from 'prop-types'
import '../../index.css'

const Contact = ({ formState, onChangeFormState }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const isTablet = !isPhone && !isDesktop
  const [suffix, setSuffix] = useState('PST')

  useEffect(() => {
    var date = new Date();
    var offsetInHours = date.getTimezoneOffset() / 60;
    if (offsetInHours === 7) { setSuffix('PDT') }
  },[])

  return (
    formState === 'Error' ? <FormError /> : formState === 'Confirm' ? <FormConfirm /> :
    <div className='page' style={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden"
    }}>
      <div style={{display:"flex", flexDirection:"column", textAlign:isPhone ? "left" : "center"}}>
        <div style={{margin:isDesktop ? "" : "1rem 2rem"}}>
          <h1 style={{fontSize:isDesktop ? "2rem" : "1.5rem"}}>Contact Us</h1>
        </div>
        <div style={{margin:isDesktop ? "2rem 0 0 0" : "2rem 2rem 0 2rem"}}>
          <Typography variant="p" fontSize={isDesktop ? 22 : 18} fontWeight="normal">
            Our dedicated team will solve your technical issues and help answer any other questions.
          </Typography>
        </div>
        <div style={{margin:isDesktop ? "0.5rem auto" : isTablet ? "1rem auto 2rem auto" : "2rem", display:"inline-flex", textAlign:isPhone ? "left" : "center"}}>
          <Typography variant="p" fontSize={isDesktop ? 22 : 18} fontWeight="normal">
            For immediate assistance, {isDesktop || isTablet ? '' : <br></br> }call
            <Link to="tel:+18187930070">
              <Typography sx={{ml:"0.3rem"}} variant="p" color={colors.green['primary']} fontSize={isDesktop ? 22 : 18} fontWeight="normal">
                818-793-0070.
              </Typography>
            </Link>
          </Typography>
        </div>
      </div>
      <div style={{display:"flex", flexDirection:"column", margin:isDesktop ? "0 5rem" : "0 2rem"}}>
        <ContactForm onChangeState={onChangeFormState}/>
      </div>
      <div style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
        <div style={{display:"flex", flexDirection:"row", textAlign:"left", justifyContent:isPhone ? "normal" : "start"}}>
          <div style={{margin:isDesktop ? "1rem 0 0 7rem": "2rem"}}>
            <Typography variant="p" fontSize={isDesktop ? 17 : 18} fontWeight="normal">
              Office Address:<br></br>
            </Typography>
            <Typography variant="p" fontSize={isDesktop ? 17 : 18} fontWeight="normal">
              7 Layer IT Solutions, Inc.<br></br>
            </Typography>
            <Typography variant="p" fontSize={isDesktop ? 17 : 18} fontWeight="normal">
              8921 Fullbright Avenue<br></br>
              Los Angeles, California 91311
            </Typography>
            <div style={{margin:"0.5rem auto 2rem auto", display:"flex", flexDirection:"row"}}>
               <Link target="_blank" to="https://maps.app.goo.gl/PvymuW771qEaCbjz9">
              <Typography variant="p" color={colors.green['primary']}  fontSize={isDesktop ? 17 : 18} fontWeight="bold">
                Map
              </Typography>
              </Link>
            </div>
            <div style={{ marginTop: "2rem", textAlign:"left"}}>
              <Typography variant="p" fontSize={isDesktop ? 17 : 18} fontWeight="normal">
                Business Hours:<br></br>
                Monday through Friday<br></br>
                7:00 AM to 7:00 PM {suffix}
              </Typography>
            </div>
          </div>
        </div>
    </div>
    </div>
  )
}

Contact.propTypes = {
  formState:PropTypes.string.isRequired,
  onChangeFormState: PropTypes.func.isRequired
}

export default Contact