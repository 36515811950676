import Service from "../../components/Service"

const data = {
  name:"Unlimited Technical Support",
  title:"24/7 Unlimited Technical Support For Your Business",
  description:"Enjoy unlimited technical support to keep your business running smoothly. Expert help available 24/7 for all your tech challenges and issues.",
  image:"/unlimited-technical-support.webp",
  text:["Whether your company opens one or 100 service requests during the month, there is no additional charge. All technical support is included in your monthly charges. Trust us, as much as we LOVE our clients, we don’t want you to have to call us for IT issues. We are very serious about setting up standards and implementing solid networks that work right. We would much rather spend time communicating with our clients about how we can add value to their businesses."],
  url:"/unlimited-technical-support",
  alt:"Unlimited Technical Support"
}

const UnlimitedTechnicalSupport = () => {
  return (
    <Service data={data} />
  )
}

export default UnlimitedTechnicalSupport