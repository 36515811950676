import Service from "../../components/Service"

const data = {
  name:"Structured Cabling & Electrical",
  title:"Best Structured Cabling & Electrical Services in LA",
  description:"Get expert structured cabling and electrical services for your business. Reliable solutions for seamless connectivity and safe electrical setups.",
  image:"/structured-cabling.svg",
  text:["Setting up a new office or expanding your current one? Whether you need Cat 3, Cat 5e, Cat 6, Cat 7, Fiber or Audio/Video, our team can design and implement a network cabling and electrical solution specifically tailored for your office needs. With our longstanding relationships in the industry, we can deploy and manage these projects so you know it will be done correctly, the first time."],
  url:"/structured-cabling-and-electrical-services",
  alt:"Structured Cabling and Electrical Services"
}

const StructuredCablingServices = () => {
  return (
    <Service data={data} />
  )
}

export default StructuredCablingServices