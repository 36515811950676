import Seo from '../../components/Seo'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import ServiceNavMenu from '../../components/ServiceNavMenu'
import { FaCrosshairs } from "react-icons/fa6" /* EDR */
import { FaNetworkWired } from 'react-icons/fa' /* SOC */
import { SiSecurityscorecard } from "react-icons/si" /* Vulnerability Management */
import { FaUserShield } from 'react-icons/fa6' /* Security Awareness Training */
import { MdOutlineMailLock } from "react-icons/md"; /* Encrypted Email */
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined' /* MDM */
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined' /* Penetration Testing */
import { MdOutlineMail } from "react-icons/md"

const data = [
  {
    "name": "Endpoint Detection & Response",
    "url": "/endpoint-threat-detection-and-response",
    "icon": <FaCrosshairs fontSize={32} />
  },
  {
    "name": "Managed Security Operations Center",
    "url": "/managed-security-operations-center",
    "icon": <FaNetworkWired fontSize={32} />
  },
  {
    "name": "Internal & External Vulnerability Management",
    "url": "/vulnerability-management",
    "icon": <SiSecurityscorecard fontSize={32} />
  },
  {
    "name": "Virtual Network Penetration Testing",
    "url": "/virtual-network-penetration-testing",
    "icon": <SecurityOutlinedIcon style={{fontSize:32}} />
  },
  {
    "name": "Security Awareness Training",
    "url": "/security-awareness-training",
    "icon": <FaUserShield style={{fontSize:32}} />
  },
  {
    "name": "Mobile Device Management",
    "url": "/mobile-device-management",
    "icon": <PhoneIphoneOutlinedIcon style={{fontSize:32}} />
  },
  {
    "name": "Encrypted Email",
    "url": "/encrypted-email",
    "icon": <MdOutlineMailLock fontSize={32} />
  },
  {
    "name": "Advanced Email Threat Protection",
    "url": "/advanced-email-threat-protection",
    "icon": <MdOutlineMail fontSize={32} />
  }
]

const Cybersecurity = (props) => {
  return (
    <div className='page'>
      <Seo 
        title="Expert Network & Cybersecurity Services Provider in LA"
        description="Secure your business with top-notch network and cybersecurity solutions. Protect your data, systems, and operations from online threats today!"
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/network-and-cybersecurity"
      />
      <div className='page-container'>
        <div className='page-title'>
          <h1>Network & Cybersecurity</h1>
        </div>
        <div className='contents'>
          <p>
            Our multi-layered, best-of-breed approach to network security ensures all aspects of the network are covered.
          </p>
        </div>
      </div>
      <ServiceNavMenu data={data} />
      <ScrollToTop {...props} />
    </div>
    )
  }


export default Cybersecurity