import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Solutions from '../Solutions'
import './index.css'


const PageNotFound = ({ location }) => {
  const navigate = useNavigate()

  const [pathname] = useState(location)

  useEffect(() => {
    if (!sessionStorage.getItem('navigated')) {
      sessionStorage.setItem('navigated', 'true')
      navigate('/404')
    }
  },[navigate])

  return (
    <div className='page'>
      <div className='page-container'>
        <div className='page-title'>
          <h1>404</h1>
        </div>
        <div className='content-center'>
          <div className='content-section'>
            <p>The requested url {pathname} was not found.</p>
          </div>
        </div>
        <div className='content-container'>
          <Solutions page='404' buttonText='Get Assistance'/>
        </div>
      </div>
    </div>
  )
}

PageNotFound.propTypes = {
  location: PropTypes.string.isRequired
}

export default PageNotFound