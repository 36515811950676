import Service from "../../components/Service"

const data = {
  name:"Endpoint Threat Detection & Response (EDR)",
  title:"Endpoint Detection and Response (EDR) Solutions LA",
  description:"Protect your organization with advanced Endpoint Detection and Response (EDR) solutions. Detect, analyze, and respond to cyber threats in real time.",
  image:"/endpoint-detection-response.svg",
  text:["Many of today’s cybercriminals can bypass traditional defenses at will. This leaves businesses exposed to ransomware, credential harvesting, data theft, extortion, and other types of attacks that can cost thousands of dollars per hour from the time of the known attack until remediation. Now more than ever, businesses need advanced Endpoint Detection & Response (EDR) in addition to having an antivirus (AV) installed on each endpoint.",
        "EDR is a behavior-based system that enables the detection of and response to sophisticated attacks in real time with continuous endpoint monitoring, deep-memory analysis to monitor for attackers that attempt to lay in wait, mapping of certain alerts and behaviors that mimic security threats onto the MITRE ATT&CK Framework and integration into a Managed Security Operations Center (SOC) Team for 24/7 real-time analysis and actions. Endpoints can be isolated, processes can be terminated, files can be deleted and that is just the beginning. EDR is quickly becoming a must have for Cyber Insurance qualifications as well."],
  url:"/endpoint-threat-detection-and-response",
  alt:"Endpoint Threat Detection and Response"
}

const EndpointDetectionResponse = () => {
  return (
    <Service data={data} />
  )
}

export default EndpointDetectionResponse