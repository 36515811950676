import Service from "../../components/Service"

const data = {
  name:"Voice over Internet Protocol (VoIP)",
  title:"Voice over Internet Protocol (VoIP) Services for Business",
  description:"Upgrade your communication with reliable VoIP services. Enjoy cost-effective, high-quality voice calls and advanced features for seamless connectivity.",
  image:"/voip.svg",
  text:["Voice over Internet Protocol (VoIP) is a technology that uses the internet to make and receive phone calls. When setup properly, a true VoIP system could save your business up to 75% on phone bills.",
        "Our VoIP services include a mobile application to give you all the benefits of having your office phone in your pocket. You’ll no longer need to share your mobile phone number with clients and vendors. Connect multiple offices together and transfer calls for free instead of paying the phone company. The desktop application displays who is on the phone, with who and for how long, and you can send them a private message while still on the call! Send and receive faxes electronically to increase security and cut costs. These are just a few of the many features included with a true Voice over IP solution.",
        "Contact us to learn how you can save on phone bills and love your phone system again!"],
  url:"/voip",
  alt:"VoIP Services"
}

const VoiceOverInternetProtocol = () => {
  return (
    <Service data={data} />
  )
}

export default VoiceOverInternetProtocol