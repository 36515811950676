import Service from "../../components/Service"

const data = {
  name:"Technology Procurement",
  title:"Technology Procurement Services for Smart Investments",
  description:"Streamline your tech buying process with expert technology procurement services. Find the best solutions to meet your business needs and budget.",
  image:"/technology-procurement.svg",
  text:["Purchasing technology hardware, software, licensing, etc. can be very time-consuming and tricky. If you don’t know the right technologies or don’t have the right relationships, you’ll end up spending more than you need and wasting a bunch of time.",
        "Let us be the purchasing department for your company. Simply let us know what you are looking for and let us do all the footwork. We have partnerships with the best manufacturers and distributors in technology and can get anything from one PC to an entire office solution. Our sales team will recommend the right fit for your needs without overselling you."],
  url:"/technology-procurement-services",
  alt:"Technology Procurement"
}

const TechnologyProcurement = () => {
  return (
    <Service data={data} />
  )
}

export default TechnologyProcurement