import { Link } from 'react-router-dom'
import ScrollToTop from '../../components/buttons/ScrollToTop'

const Cookies = (props) => {
  return (
    <div className='page'>
      <div className='page-container'>
        <div className='page-title'>
          <h1>Cookies Policy</h1>
        </div>
        <div className='content-container'>
          <div className='legal-content-section'>
            <p>This Cookies Policy explains how we use cookies and similar tracking technologies when you visit our website.</p>
          </div>
          <div className='legal-content-section'>
            <h2>What Are Cookies?</h2>
            <p>Cookies are small text files stored on your device when you visit a website. They help improve user experience by remembering preferences, enabling website functionality, and analyzing site traffic.</p>
          </div>
          <div className='legal-content-section'>
            <h2>How We Use Cookies</h2>
            <p>We use cookies to:</p>
            <ul>
              <li><p>Enhance website performance and functionality.</p></li>
              <li><p>Remember user preferences and settings.</p></li>
              <li><p>Analyze website traffic and trends.</p></li>
              {/* <li><p>Provide personalized content and advertisements.</p></li> */}
              <li><p>Improve security and prevent fraudulent activities.</p></li>
            </ul>
          </div>
          <div className='legal-content-section'>
            <h2>Types of Cookies We Use</h2>
            <h3>Essential Cookies</h3>
            <p>
              These cookies are necessary for website functionality and cannot be disabled. They include authentication, security, and accessibility-related cookies.
            </p>
            <h3>Performance and Analytics Cookies</h3>
            <p>
              These cookies help us analyze website traffic and user behavior to enhance site performance. They allow us to track which pages are visited most often and how users interact with our website.
            </p>
            <h3>Functional Cookies</h3>
            <p>
              These cookies enable enhanced functionality, such as remembering user preferences and providing a personalized experience.
            </p>
            <h3>Advertising and Targeting Cookies</h3>
            <p>
              These cookies are used to display relevant advertisements based on browsing behavior. They help measure ad effectiveness and limit the number of times you see a particular ad.
            </p>
          </div>
          <div className='legal-content-section'>
            <h2>Third-Party Cookies</h2>
            <p>We may allow third-party service providers, such as Google Analytics and advertising partners, to place cookies on our website. These cookies help analyze user behavior and provide personalized ads.</p>
            <p>For more information on how Google uses data from our site, please visit Google’s Privacy & Terms.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Managing Cookies</h2>
            <p>
              You can manage or disable cookies through your browser settings. However, disabling essential cookies may affect website functionality.
            </p>
            <p>
              Steps to Manage Cookies in Popular Browsers:
            </p>
            <p>
            <ul>
              <li><p><b>Google Chrome:</b> Settings &gt; Privacy and Security &gt; Cookies and Other Site Data</p></li>
              <li><p><b>Mozilla Firefox:</b> Options &gt; Privacy & Security &gt; Cookies and Site Data</p></li>
              <li><p><b>Safari:</b> Preferences &gt; Privacy &gt; Manage Website Data</p></li>
              <li><p><b>Microsoft Edge:</b> Settings &gt; Cookies and Site Permissions</p></li>
            </ul>
            </p>
            <p>For more information, visit <Link to='https://www.allaboutcookies.org'><b>www.allaboutcookies.org</b></Link>.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Updates to This Policy</h2>
            <p>We may update this Cookies Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>
          </div>
          <div className='legal-content-section'>
            <h2>Contact Us</h2>
            <p>If you have any questions about this policy, please <Link to="/contact"><b>contact us</b></Link>.</p>
            <p>By continuing to use our website, you consent to the use of cookies as described in this policy.</p>
          </div>
        </div>
      </div>
      <ScrollToTop {...props} />
    </div>
  )
}

export default Cookies
