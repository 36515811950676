import Service from "../../components/Service"

const data = {
  name:"Virtual Network Penetration Testing",
  title:"Virtual Network Penetration Testing for Cybersecurity",
  description:"Enhance your cybersecurity with virtual network penetration testing. Identify vulnerabilities, assess risks, and protect your business from cyber threats.",
  image:"/penetration-testing.svg",
  text:["vPenTesting is an automated network penetration testing platform that combines the knowledge, methodology, processes, and toolsets of a hacker into a single, deployable SaaS platform for organizations of all sizes. vPenTest allows organizations to perform a penetration test within their environment at any given time, satisfying both compliance requirements as well as meeting security best practices. This platform is developed and maintained solely by Vonahi Security and is based on a framework that continuously improves over time."],
  url:"/virtual-network-penetration-testing",
  alt:"Virtual Network Penetration Testing"
}

const PenetrationTesting = () => {
  return (
    <Service data={data} />
  )
}

export default PenetrationTesting