const FormError = () => {
  return (
    <div className='page'>
      <div className='page-spacer' />
      <div className='page-container'>
        <div className='page-title'>
          <h1>We&apos;re Sorry</h1>
        </div>
        <div className='content-center'>
          <div className='content-section'>
          <p>There was a problem submitting your request. Please try again.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FormError