import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const FadeIn = (props) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  useEffect(() => {
    const currentState = domRef.current
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setVisible(entry.isIntersecting);
            }
        });
    });
    observer.observe(currentState);
    return () => observer.unobserve(currentState);
  }, []);
  return (
      <div
          className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
          ref={domRef}
      >
          {props.children}
      </div>
  );
}

FadeIn.propTypes = {
  children: PropTypes.any
}


export default FadeIn