import Service from "../../components/Service"

const data = {
  name:"Encrypted Email",
  title:"Secure Encrypted Email Solutions for Your Business",
  description:"Protect sensitive communications with encrypted email solutions. Ensure privacy, prevent data breaches, and enhance security for your organization.",
  image:"/encrypted-email.svg",
  text:["Important personal and corporate data is sent via email all the time. The problem is that attackers can easily capture email data sent over clear text. Now more than ever, it is important to ensure that any important data such as account numbers, social security numbers, passwords, etc. are sent over a secure channel making it much more difficult for this information to be intercepted. If you are looking for a solution that seamlessy integrates into your business email and is super easy to use, give us a call!"],
  url:"/encrypted-email",
  alt:"Encrypted Email"
}

const EncryptedEmail = () => {
  return (
    <Service data={data} />
  )
}

export default EncryptedEmail