import Action from '../buttons/Action'
import { serviceList } from '../../static/serviceList'
import './index.css'

const ManagedServices = () => {
  return (
    <section className='managed-services-container'>
      <div className='managed-services-title'>
        <h1>
          Providing businesses and non-profit organizations with affordable
          IT solutions through customized, secure, and reliable network services.
        </h1>
      </div>
      <div className='managed-services'>
        {serviceList.map((service, serviceIndex) => {
          return (
            <div className='managed-service-item' key={serviceIndex}>
              <img src={service.image} alt={service.alt} />
              <div className='managed-service-content'>
                <div className='managed-service-header'>
                  <h2>{service.service}</h2>
                </div>
                <div className='managed-service-subtitle'>
                  <h3>{service.title}</h3>
                </div>
                <hr/>
                <div className='managed-service-text'>
                  <p>{service.text}</p>
                </div>
                <div className='managed-service-button'>
                  <Action
                    to={service.to}
                    text='Learn More'
                    shadow={false}
                    aria={service.aria}
                  />
                </div>
              </div>
            </div>
        )})}
      </div>
    </section>
  )
}

export default ManagedServices