import { useMediaQuery, useTheme } from '@mui/material'
import ManagedServices from '../../components/ManagedServices'
import Clients from '../../components/Clients'
import Partners from '../../components/Partners'
import Video from '../../components/Video'
import Carousel from '../../components/Carousel'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import { Helmet } from 'react-helmet-async'

const Home = (props) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  const meta = {
    "title": "Best IT Services and Solutions Providers in LA, California",
    "description": "Explore the best IT services and solutions providers in LA, California. Discover experts offering tailored tech solutions for your business needs.",
    "url": "https://7lits.com"
  }

  const slides = [
    {
      className:'img-managed-it-services',
      url:`/managed-it-services-los-angeles-first-frame.webp`,
      description: ['MANAGED IT SERVICES'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services',
      alt:'Managed IT Services'
    },
    {
      className:'img-cloud-services-los-angeles',
      url:`/cloud-services-los-angeles.jpg`,
      description: ['CLOUD7 SOLUTIONS'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services#cloud-services',
      alt:'Cloud Services'
    },
    {
      className:'img-cybersecurity fade-in-image',
      url:`/cybersecurity-los-angeles.jpg`,
      description: ['CYBERSECURITY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/network-and-cybersecurity',
      alt:'Cybersecurity'
    },
    {
      className:'img-disaster-recovery fade-in-image',
      url:`/business-data-recovery-los-angeles.jpg`,
      description: ['BUSINESS CONTINUITY &','DISASTER RECOVERY'],
      text:'Centralize application and data access with our private cloud solution.',
      buttonText:'Learn More',
      to:'/managed-it-services',
      alt:'Business Continuity and Disaster Recovery'
    },
  ]

  const jsonData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "logo": "https://7lits.com/logo-7l-full.webp",
    "name": "7 Layer IT Solutions",
    "legalName": "7 Layer IT Solutions, Inc.",
    "url": "https://7lits.com",
    "description":"IT Services",
    "keywords":["IT Services","Cybersecurity","Network Services"],
    "image":[
      "https://7lits.com/cloud-services-los-angeles.jpg"
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "8921 Fullbright Avenue",
      "addressLocality": "Chatsworth",
      "addressRegion": "Los Angeles",
      "postalCode": "91311",
      "addressCountry": "US"
    },
    "contactPoint" : [
        { "@type" : "ContactPoint",
          "telephone" : "+18187930070",
          "email": "support@7lits.com",
          "contactType" : "Customer Service",
          "areaServed" : "US"
        }
    ],
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 34.232845506176105,
      "longitude": -118.57792319076856
    },
    "telephone": "+18187930070",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "07:00",
        "closes": "19:00"
      }
    ],
    "sameAs": [
      "https://www.linkedin.com/company/7lits",
      "https://www.facebook.com/7lits",
      "https://www.instagram.com/7lits",
      "https://www.yelp.com/biz/7-layer-it-solutions-chatsworth",
      "https://maps.app.goo.gl/nBaianyM2RhBLg3g8"
    ],
    "foundingDate": "2007",
    "founders": [
      {
        "@type": "Person",
        "name": "Angel Cifuentes"
      },
      {
        "@type": "Person",
        "name": "Michael Sagoian"
      }
    ]
  }

  return (
    <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      overflow:"hidden"
    }}>
      <Helmet>
        <title>{meta.title}</title>
        <link rel="canonical" href={meta.url} />
        <link rel="preload" href="/managed-it-services-los-angeles-first-frame.webp" as="image" type="image/webp" />
        <meta name="description" content={meta.description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta name="twitter:creator" content="7 Layer IT Solutions" />
        <meta name="twitter:card" content="website" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <script type="application/ld+json">{JSON.stringify(jsonData)}</script>
      </Helmet>
      {isDesktop && <Video />}
      {!isDesktop && <Carousel slides={slides} />}
      <ManagedServices />
      <Clients />
      <Partners isDesktop={isDesktop} />
      <ScrollToTop {...props} />
      </div>
  )
}

export default Home