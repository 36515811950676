const FormConfirm = () => {
  return (
    <div className='page'>
      <div className='page-spacer' />
      <div className='page-container'>
        <div className='page-title'>
          <h1>Thank you!</h1>
        </div>
        <div className='content-center'>
          <div className='content-section'>
          <p>We received your request and will get in touch with you soon.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FormConfirm