import { Link } from 'react-router-dom'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import { FaWindows } from "react-icons/fa6"
import { FaApple } from "react-icons/fa6"
import Action from '../../components/buttons/Action'
import { BiSupport } from "react-icons/bi"
import { LuTicket } from "react-icons/lu";
import { useEffect, useState } from 'react'
import Seo from '../../components/Seo'

function detectOS() {
  const os = navigator.userAgentData.platform;
  if (os === 'Windows') {
    return 'Windows';
  } else if (os === 'OS X' || os === 'macOS') {
    return 'Mac';
  } else if (os === 'iOS') {
    return 'iOS';
  } else if (os === 'Android') {
    return 'Android';
  } else if (os === 'Linux') {
    return 'Linux';
  }
  return 'Unknown OS';
}

const Support = (props) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)
  const isPhone = useMediaQuery(theme.breakpoints.down('tablet'))
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
  const deviceOS = detectOS()
  const [suffix, setSuffix] = useState('PST')

  useEffect(() => {
    var date = new Date();
    var offsetInHours = date.getTimezoneOffset() / 60;
    if (offsetInHours === 7) { setSuffix('PDT') }
  },[suffix])

  return (
    <div className='page'>
      <Seo 
        title="Support"
        description=""
        type="website"
        name="7 Layer IT Solutions"
        url="https://7lits.com/support"
      />
      <div className='page-container'>
        <div className='page-title'>
          <h1>Support</h1>
        </div>
        <div className='content-center'>
          <div className='content-section'>
            <p>Unlimited support requests included in all service plans.</p>
          </div>
        </div>
        <div className='support-container'>
          <div className='support-item' style={{

          }}>
            <BiSupport fontSize={30} color={colors.green['primary']} style={{opacity:"0.5", marginBottom:"0.5rem"}}/>
            <Typography fontFamily='Inter' fontWeight={1000} fontSize={20}>Call Technical Support</Typography>
            <Typography sx={{mt:"1rem", p:"0 0.5rem"}}>Office hours Monday through Friday,<br></br>7:00AM to 7:00PM {suffix}.</Typography>
            <Link to="tel:+18187930070"><Typography variant='h1' fontSize={21} color="#077000" sx={{mt:"1.5rem", opacity:0.7}}>818-793-0070</Typography></Link>
            <div style={{position:"absolute", left:0, right:0, bottom:"2rem"}}><Action text='Call Support' to='tel:+18187930070' shadow={true} aria="call-support"/></div>
          </div>
          

          {deviceOS === 'Windows' && <div style={{
            position:"relative",
            margin:isPhone ? "1rem auto" : "1rem",
            height:isMobile ? "24rem" : "22rem",
            width:isPhone ? "85%" : "23rem",
            display:"inline-block",
            overflow:"hidden",
            padding:isPhone ? "1rem" : "2rem",
            borderRadius:"0.5rem",
            backgroundColor: mode === 'light' ? colors.grey[900] : colors.grey[700],
            /* boxShadow: theme.palette.mode == 'light' ? "1px 10px 15px #d3d3d3" : "", */
          }}>
            <div>
              <FaWindows fontSize={30} color={colors.green['primary']} style={{opacity:"0.5", marginBottom:"0.5rem"}}/>
              <Typography fontFamily='Inter' fontWeight={1000} fontSize={20}>Download Windows Agent</Typography>
              <Typography sx={{mt:"1rem", p:"0 0.5rem"}} fontSize={15}>Click to download the remote support agent, then launch the downloaded file.</Typography>
              <Typography sx={{mt:"1rem", p:"0 0.5rem"}} fontSize={15}>After installing, give the 9-digit code<br></br>to the IT admin assisting you.</Typography>
              <div style={{position:"absolute", left:0, right:0, bottom:"2rem"}}><Action target='_blank' text='Download Agent' to='https://my.splashtop.com/sos/packages/generic-download/TPSWA35JJ5H3' shadow={true} aria="download-support-agent"/></div>
            </div></div>}

            {deviceOS === 'Mac' && <div style={{
            position:"relative",
            margin:isPhone ? "1rem auto" : "1rem",
            height:isMobile ? "24rem" : "22rem",
            width:isPhone ? "85%" : "23rem",
            display:"inline-block",
            overflow:"hidden",
            padding:isPhone ? "1rem" : "2rem",
            borderRadius:"0.5rem",
            backgroundColor: mode === 'light' ? colors.grey[900] : colors.grey[700],
            /* boxShadow: theme.palette.mode == 'light' ? "1px 10px 15px #d3d3d3" : "", */
          }}>
            <div>
              <FaApple fontSize={30} color={colors.green['primary']} style={{opacity:"0.5", marginBottom:"0.5rem"}}/>
              <Typography fontFamily='Inter' fontWeight={1000} fontSize={20}>Download Mac Support Agent</Typography>
              <Typography sx={{mt:"1rem", p:"0 0.5rem"}} fontSize={15}>Click to download the remote support agent, then launch the downloaded file.</Typography>
              <Typography sx={{mt:"1rem", p:"0 0.5rem"}} fontSize={15}>After installing, give the 9-digit code<br></br>to the IT admin assisting you.</Typography>
              <div style={{position:"absolute", left:0, right:0, bottom:"2rem"}}><Action target='_blank' text='Download Agent' to='https://my.splashtop.com/sos/packages/generic-download/TPSWA35JJ5H3' shadow={true} aria="download-support-agent"/></div>
            </div></div>}
          
          <div style={{
            position:"relative",
            margin:isPhone ? "1rem auto" : "1rem",
            height:isMobile ? "25rem" : "22rem",
            width:isPhone ? "85%" : "23rem",
            display:"inline-block",
            overflow:"hidden",
            padding:isPhone ? "1rem" : "2rem",
            borderRadius:"0.5rem",
            backgroundColor: mode === 'light' ? colors.grey[900] : colors.grey[700],
            /* boxShadow: theme.palette.mode == 'light' ? "1px 10px 15px #d3d3d3" : "", */
          }}>
            <div>
              <LuTicket fontSize={30} color={colors.green['primary']} style={{opacity:"0.5", marginBottom:"0.5rem"}}/>
              <Typography fontFamily='Inter' fontWeight={1000} fontSize={20}>Create a Support Ticket</Typography>
              <Typography sx={{mt:"1rem", p:"0 0.5rem"}} fontSize={15}>Send an email to our support team to create a new ticket.</Typography>
              <Typography sx={{mt:"1rem", p:"0 0.5rem"}} fontSize={15}>One of our IT technicians will reply to you as quickly as possible.</Typography>
              <div style={{position:"absolute", left:0, right:0, bottom:"2rem"}}><Action text='Email Support' to='mailto:support@7lits.com' shadow={true} aria="email-support"/></div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop {...props} />
      </div>
  )
}

export default Support